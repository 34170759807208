<template>
  <div class="detail-index-bg">
    <div class="detail-index" v-loading="pageLoading">
      <div class="detail-index-crumbs">
        <span @click="goBack()" style="color:black;cursor:pointer;margin-right:0.1rem"
          >线下培训</span
        >/<div class="detail-index-crumbs-this">{{info.title}}</div>
      </div>
      <div class="detail-index-top">
        <div class="detail-index-top-content-headPic">
          <img
            style="width: 100%; height: 100%"
            src="https://image.bookgo.com.cn/webculture/jm/border-top.png"
            alt=""
          />
        </div>
        <div class="detail-index-top-content-footerPic">
          <img
            style="width: 100%; height: 100%"
            src="https://image.bookgo.com.cn/webculture/jm/border-bottom.png"
            alt=""
          />
        </div>
        <div class="detail-index-top-left">
          <img style="width: 100%; height: 100%" :src="info.banner" alt="" />
        </div>
        <div class="detail-index-top-right">
          <div class="activity-detail-top-title">{{ info.title }}</div>
          <div class="activity-detail-top-status">
            <div
              class="activity-detail-top-status-finished"
              v-if="info.status == 1"
            >
              {{ info.status_text }}
            </div>
            <div
              class="activity-detail-top-status-applying"
              v-else-if="info.status == 2"
            >
              {{ info.status_text }}
            </div>
            <div
              class="activity-detail-top-status-applyingend"
              v-else-if="info.status == 3"
            >
              {{ info.status_text }}
            </div>
            <div
              class="activity-detail-top-status-doing"
              v-else-if="info.status == 4"
            >
              {{ info.status_text }}
            </div>
            <div
              class="activity-detail-top-status-notbegin"
              v-else-if="info.status == 5"
            >
              {{ info.status_text }}
            </div>
          </div>
          <div class="activity-detail-top-des">
            <ul class="activity-detail-top-des-ul">
              <li class="activity-detail-top-des-ul-li">
                <div class="activity-detail-top-des-ul-li-icon">
                  <img
                    style="width: 100%; height: 100%"
                    src="https://image.bookgo.com.cn/webculture/jm/detail/detail_top_date_icon.png"
                    alt=""
                  />
                </div>
                <div class="activity-detail-top-des-ul-li-label" style="width:65px">
                  举办时间：
                </div>
                <div>
                  {{ info.active_start_time }}～ {{ info.active_end_time }}
                </div>
              </li>
              <li class="activity-detail-top-des-ul-li" style="align-items:flex-start">
                <div class="activity-detail-top-des-ul-li-icon">
                  <img
                    style="width: 100%; height: 100%"
                    src="https://image.bookgo.com.cn/webculture/jm/detail/detail_top_address_icon.png"
                    alt=""
                  />
                </div>
                <div class="activity-detail-top-des-ul-li-label" style="width:65px">
                  举办地点：
                </div>
                <div style="width:3.9rem;display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 5;word-break: break-all;">{{ info.address }}{{info.sponsor_place}}</div>
              </li>
              <li class="activity-detail-top-des-ul-li">
                <div class="activity-detail-top-des-ul-li-icon">
                  <img
                    style="width: 100%; height: 100%"
                    src="https://image.bookgo.com.cn/webculture/jm/detail/detail_top_phone_icon.png"
                    alt=""
                  />
                </div>
                <div class="activity-detail-top-des-ul-li-label">
                  联系电话：
                </div>
                <div>
                  {{ info.telephone }}
                </div>
              </li>
                            <li class="activity-detail-top-des-ul-li">
                <div class="activity-detail-top-des-ul-li-icon">
                  <img
                    style="width: 100%; height: 100%"
                    src="https://image.bookgo.com.cn/webculture/jm/detail/detail_speaker_culture.png"
                    alt=""
                  />
                </div>
                <div class="activity-detail-top-des-ul-li-label">
                  主讲人：
                </div>
                <div>
                  {{ info.teacher }}
                </div>
              </li>
            </ul>
          </div>
          <div
            class="activity-detail-top-button"
            v-if="info.status == 4 || info.status == 2"
            @click="handleServe"
            style="cursor: pointer"
          >
            我要预约
          </div>
          <a href="javascript:void(0)" @click="handleServe">
            <div class="activity-detail-top-button" v-if="info.status == 2 && residue_num == 0">
              我要候补
            </div>
          </a>
          <div
            class="activity-detail-top-button background-gray"
            v-if="info.status == 1"
          >
            活动已结束
          </div>
          <div
            class="activity-detail-top-button"
            v-if="info.status == 3 || info.status == 5"
            :class="info.need_reserve ? 'background-pink' : 'background-hui'"
          >
            {{info.need_reserve?'活动未开始':'免报名'}}
          </div>

        </div>
      </div>
      <div class="detail-index-bottom">
        <div class="detail-index-bottom-content">
          <div class="detail-index-top-content-footerPic">
            <img
              style="width: 100%; height: 100%"
              src="https://image.bookgo.com.cn/webculture/jm/border-bottom.png"
              alt=""
            />
          </div>
          <div class="detail-index-bottom-content-top">
            <div class="detail-index-bottom-content-top-title">活动详情</div>
          </div>

          <div class="detail-index-bottom-content-bottom">
            <div class="detail-index-bottom-content-bottom-box">
              <div class="detail-index-bottom-content-bottom-box-top">
                <div class="detail-index-bottom-content-bottom-box-top-icon">
                  <img
                    style="width: 100%; height: 100%"
                    src="https://image.bookgo.com.cn/webculture/jm/detail/detail_title_left_icon.png"
                    alt=""
                  />
                </div>
                <div class="detail-index-bottom-content-bottom-box-top-title">
                  举办单位
                </div>
              </div>
              <div class="detail-index-bottom-content-bottom-box-bottom">
                <div>主办单位：{{ info.sponsor }}</div>
                <div>承办单位：{{ info.organizer }}</div>
                <div>协办单位：{{ info.assistor }}</div>
              </div>
            </div>
            <div class="detail-index-bottom-content-bottom-box">
              <div class="detail-index-bottom-content-bottom-box-top">
                <div class="detail-index-bottom-content-bottom-box-top-icon">
                  <img
                    style="width: 100%; height: 100%"
                    src="https://image.bookgo.com.cn/webculture/jm/detail/detail_title_left_icon.png"
                    alt=""
                  />
                </div>
                <div class="detail-index-bottom-content-bottom-box-top-title">
                  剩余名额
                </div>
              </div>
              <div class="detail-index-bottom-content-bottom-box-bottom" v-if="info.limit_num > 0&&info.round_type == 0">
                <div>剩余{{ info.total_residue_num }}个总名额</div>
              </div>
              <div class="detail-index-bottom-content-bottom-box-bottom" v-if="info.need_reserve==1 && info.limit_num > 0&&info.round_type == 1">
                <div v-for="(item,i) in info.active_round">
                  {{ item.round_name }} {{item.start_time}} ~ {{item.end_time}} 剩余 {{item.limit_num-item.signup_num>0?item.limit_num-item.signup_num:0}}
                </div>
              </div>

			        <div class="detail-index-bottom-content-bottom-box-bottom" v-if="info.need_reserve==1 && info.limit_num <= 0">无人数限制</div>
			        <div class="detail-index-bottom-content-bottom-box-bottom" v-if="info.need_reserve==0">免报名</div>


            </div>
            <div class="detail-index-bottom-content-bottom-box">
              <div class="detail-index-bottom-content-bottom-box-top">
                <div class="detail-index-bottom-content-bottom-box-top-icon">
                  <img
                    style="width: 100%; height: 100%"
                    src="https://image.bookgo.com.cn/webculture/jm/detail/detail_title_left_icon.png"
                    alt=""
                  />
                </div>
                <div class="detail-index-bottom-content-bottom-box-top-title">
                  活动简介
                </div>
              </div>
              <div
                class="detail-index-bottom-content-bottom-box-bottom"
                v-html="info.summary"
              >
                <div>{{ info.summary }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <el-dialog
      :close-on-click-modal="false"
      title="提示"
      :visible.sync="formDialogShow"
      :center="true"
    >
      <Cpopup
        :detailType="detailType"
        :title="popupTitle"
        @handle-closed="handleClosed"
        :detailSrc="detailSrc"
      />
    </el-dialog> -->
    <mydialog
      v-if="formDialogShow"
      :keyword="'扫码预定活动'"
      :isShow="formDialogShow"
      :src="detailSrc"
      @close="formDialogShow = false"
    />
  </div>
</template>

<script>
import Cpopup from "../components/Popup";
import Cdetail from "../components/Cdetail";
import { getActivityDetail, getListRound } from "@/api/activity/activity";
import { genQRCode } from "@/api/common";
import mydialog from "@/components/myDialog";
// import merge from "webpack-merge";
export default {
  components: { Cdetail, Cpopup, mydialog },
  data() {
    return {
      pageLoading: false,
      info: {},
      detailSrc: "",
      formDialogShow: false,
      aid: process.env.VUE_APP_AID,
      id: null,
      popupTitle: "扫描二维码参与文化活动",
      detailType: "activity",
      activity_team_list: [],
      active_round_array:[]
    };
  },

  created() {
    let id = this.$route.query.id;
    if (!id) {
      this.$router.push({ path: "/" });
    }
    this.id = id;
  },
  mounted() {
    let id = this.$route.query.id;
    if (!id) {
      this.$router.push({ path: "/" });
    }
    this.id = id;
    this.getListR()
    document.documentElement.scrollTop = 0;
  },
  watch: {
    $route: function(newVal) {
      // console.log(newVal,oldVal);
      this.id = newVal.query.id;
      
      this.getListR()
    },
  },
  methods: {
    changeInfo(id) {
      console.log(1);
      //   this.$router.replace( { id: id } );
      this.$router.push(`detail?id=${id}`);
      this.$router.go();
      document.documentElement.scrollTop = 0;
      //   this.id = id;
    },
    handleClosed() {},
    getInfo() {
      getActivityDetail({ id: this.id }).then((res) => {
        console.log(res);
        const regex = new RegExp("<img", "gi");
        res.data.summary = res.data.summary.replace(
          regex,
          `<img style="max-width: 100%; height: auto;margin:0 auto;"`
        );
        this.info = res.data;
        console.log(this.active_round_array)
        for(var i = 0;i < this.info.active_round.length; i++) {
          for(var j = 0;j < this.active_round_array.length;j++) {
            if(this.info.active_round[i].id == this.active_round_array[j].id) {
              console.log(this.info.active_round[i])
              this.info.active_round[i].limit_num = this.active_round_array[j].limit_num
              this.info.active_round[i].signup_num = this.active_round_array[j].signup_num
            }
          }
        }
        console.log(this.info.active_round)
      });
    },
    goBack() {
      let routeUrl = this.$router.resolve({
        path: `/offlinetrain`,
        query: { id:this.$route.query.pid,cat_id:this.$route.query.cat_id,navId:this.$route.query.navId,name:this.$route.query.name},
      });
      window.location = routeUrl.href
    //  window.open(routeUrl.href, "_blank");
    },
    getListR() {
      getListRound({ active_id: this.id }).then((res) => {
        
        this.active_round_array = res.data
        this.getInfo();
      });
    },
    handleServe() {
      let params = {
        wxapp_name: "culture",
        page:
          "bookgo/culture/activity/detail/detail?id=" +
          this.id +
          "&aid=" +
          process.env.VUE_APP_AID,
        scene: "",
        aid: process.env.VUE_APP_AID,
      };
      this.formDialogShow = true;
      genQRCode(params).then((res) => {
        let pic = res.data;
        this.detailSrc = pic;
      });
    },
  },
};
</script>

<style lang="scss">
@import "../../assets/scss/detail.scss";
@import "../../assets/scss/activity_detail.scss";
@import "../../assets/scss/dialog.scss";
</style>
